// @flow
import type { Action } from '../lib/types';

const initialState = [];

const prefilledInitialState = [
  'cervicalCap',
  'fertilityAwareness',
  'sponge',
  'spermicide',
  'withdrawal',
  'sterilization'
];

export default (state: Array<string> = initialState, action: Action): Array<string> => {
  const newState = state.slice();
  let indexOfItem;

  switch (action.type) {
    case 'TOGGLE_CURRENT_METHOD':
      indexOfItem = newState.indexOf(action.item);
      if (indexOfItem !== -1) return newState.splice(indexOfItem, 0);
      newState.push(action.item);
      return newState;
    case 'SECRET_PREFILL_CURRENT_METHODS':
      return prefilledInitialState;
    default:
      return state;
  }
};
