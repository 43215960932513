// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Location } from 'react-router';
import myBCStrings from '../strings/mybc.json';
import myPathVAStrings from '../strings/mypathVA.json';
import myPathStrings from '../strings/mypathGeneral.json';
import peripartumStrings from '../strings/peripartum.json';
import { myBCRoutes, myPathRoutes, peripartumRoutes, superDevModeRoutes } from '../routes';
import Rainbow from './shared/rainbow/Rainbow';
import NavContainer from './shared/nav/NavContainer';
import { deviceOSstr } from '../lib/utils';
import type { State } from '../lib/types';

type SelectorProps = {
  location: Location,
  isAnyModalVisible: boolean,
  history: {},
};

const superDevModeStrings = {
  en: { ...myPathStrings.en, ...peripartumStrings.en, },
  es: { ...myPathStrings.es, ...peripartumStrings.es, },
};

let routes = myBCRoutes;
let strings = myBCStrings;

if (process.env.REACT_APP_MODE === 'mypath') {
  routes = myPathRoutes;
  strings = myPathStrings;
} else if (process.env.REACT_APP_MODE === 'mypathVA') {
  routes = myPathRoutes;
  strings = myPathVAStrings;
} else if (process.env.REACT_APP_MODE === 'peripartum') {
  routes = peripartumRoutes;
  strings = peripartumStrings;
} else if (process.env.REACT_APP_MODE === 'superDev') {
  routes = superDevModeRoutes;
  strings = superDevModeStrings;
}

const titleGenerator = (pathname: string): string => {
  const pageTitleSanitizer = (str: string): string => str.replace(/#|\\n/gi, '');

  const pageKey = pathname.slice(pathname.lastIndexOf('/') + 1) || 'splash';
  if (pageKey === 'splash') return process.env.REACT_APP_WEBSITE_NAME;
  // console.log('inside TG ', pathname, pageKey)
  const locale = pathname.startsWith('/es/') ? 'es' : 'en';
  const pageHeading = strings[locale][pageKey] ? strings[locale][pageKey].headings || '' : '';
  const pageTitle = pageTitleSanitizer(pageHeading);
  // console.log(locale, pageTitle)
  return `${process.env.REACT_APP_WEBSITE_NAME}-${pageTitle}`;
};

class Selector extends React.Component<SelectorProps> {
  constructor(props: SelectorProps) {
    super(props);
    const { history } = this.props;
    // attached listener / unlistener to change document title
    this.unlisten = history.listen((location: Object) => {
      // console.log(location)
      const { pathname } = location;
      document.title = titleGenerator(pathname);
    });
    document.title = titleGenerator(window.location.pathname);
  }

  componentWillUnmount() { this.unlisten(); }

  render() {
    const { isAnyModalVisible, location: { pathname } } = this.props;
    const pageKey = pathname.slice(pathname.lastIndexOf('/') + 1) || 'splash';
    const locale = pathname.startsWith('/es/') ? 'es' : 'en';
    const pageStrings = { ...strings[locale].shared, ...strings[locale][pageKey] };
    const currentPage = routes[pageKey];

    // gate for valid paths
    if (!currentPage) return <h1>Page not found</h1>;

    const layoutClasses = `${process.env.REACT_APP_MODE} ${currentPage.additionalClasses || ''}`;
    const CurrentComponent = currentPage.component;
    const { navLinks } = currentPage;

    return (
      <>
        <Rainbow />
        <div className={`background-image ${pageKey} ${locale} ${layoutClasses} ${deviceOSstr()}`}>
          <CurrentComponent
            locale={locale}
            content={pageStrings}
            navLinks={navLinks}
            isAnyModalVisible={isAnyModalVisible}
          />
          {
            !currentPage.hideNavigation && (
              <NavContainer
                locale={locale}
                nextPage={currentPage.next}
                backPage={currentPage.back}
                hideNotes={currentPage.hideNotes}
                notesContent={pageStrings.notes}
                navigationContent={pageStrings.navigation}
              />
            )
          }
        </div>
      </>
    );
  }
}

Selector.displayName = 'Selector';

const mapStateToProps = ({ isAnyModalVisible }: State): {} => ({ isAnyModalVisible });

export default connect(mapStateToProps)(Selector);
