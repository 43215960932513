// @flow

import * as React from 'react';
import markdown, { MarkdownSyntaxRemoverFunc, hyphenReplacerFunc } from '../../lib/utils';

type BracketProps = { rangeArr: Array<string> };

type sharedBarProps = {
  resultArr: Array<string>,
  iconNameArr: Array<string>,
  selectedIconNum: number | null,
  content: Object,
};

type IconBarProps = sharedBarProps & {
  clickHandler: * => void,
  isAnyModalVisible: boolean,
  locale: string,
};
type ResultBarProps = sharedBarProps;
type MobileOnlyBracketsAndIconBarsProps = IconBarProps & {
  rangeArr: Array<string>,
  mobileConfig: Array<number>,
  isAnyModalVisible: boolean,
  mobileButtonRefs: [{}],
};

export const Brackets = ({ rangeArr }: BracketProps): React.Element<'div'> => (
  <div className="brackets-container" aria-hidden>
    {
      rangeArr.map((str: string, i: number): React.Element<'div'> => (
        <div className={`brackets bracket-${i}`} key={str}>
          <span>{str}</span>
        </div>
      ))
    }
  </div>
);

export const IconBar = ({ resultArr, iconNameArr, selectedIconNum, clickHandler, content, isAnyModalVisible, locale }: IconBarProps): React.Element<'div'> => (
  <div className="bc-bar-items-container">
    {
      iconNameArr.map((iconName: string, i: number): React.Element<'div'> => {
        const extraClass = selectedIconNum === i ? 'selected' : '';
        const mappedIconName = content.methodNames[iconNameArr[i]];

        // this is purposely made not a button because we don't want screen reader users to interact with it
        return (
          <div
            className={`bc-bar-item ${iconName} ${extraClass}`}
            key={iconName}
            onClick={() => { clickHandler(i); }}
            onKeyPress={() => { clickHandler(i); }}
            aria-hidden={isAnyModalVisible}
            tabIndex={isAnyModalVisible ? null : '0'}
          >
            <div className="screen-reader-only">{ MarkdownSyntaxRemoverFunc(hyphenReplacerFunc(`${mappedIconName}: ${resultArr[i]}`, locale)) }</div>
          </div>
        );
      })
    }
  </div>
);

export const ResultBar = ({ iconNameArr, resultArr, selectedIconNum, content }: ResultBarProps): React.Element<'div'> => (
  <div className="bc-result-container" aria-hidden>
    {
      resultArr.map((result: string, i: number): React.Element<'div'> => {
        // console.log(text)
        const mappedIconName = content.methodNames[iconNameArr[i]];
        const text = `_\`${mappedIconName}:\`_ ${result}`;

        return (
          <div
            className={i === selectedIconNum ? 'selected' : 'no-display'}
            key={`${iconNameArr[i]}-${text}`}
            dangerouslySetInnerHTML={{ __html: markdown(text) }}
          />
        );
      })
    }
  </div>
);

// const mobileConfig = [4, 4, 3];

const arrSplitter = (arr: Array<*>, configArr: Array<number>): Array<Array<*>> => (
  configArr.map((v: number): Array<*> => arr.splice(0, v))
);

export const MobileBracketsAndIconBars = ({
  clickHandler,
  mobileConfig,
  rangeArr,
  iconNameArr,
  resultArr,
  content,
  isAnyModalVisible,
  mobileButtonRefs,
  locale,
}: MobileOnlyBracketsAndIconBarsProps): React.Element<'div'> => {
  const splitIconNameArr = arrSplitter(iconNameArr.slice(), mobileConfig);
  let counter = -1;

  return (
    <div className="bc-bars-and-brackets-mobile-container mobile">
      {
        rangeArr.map((range: string, i: number): React.Element<'div'> => (
          <div className={`bracket-and-bar-group bracket-and-bar-group-${i}`} key={range}>
            {
              <div className={`brackets bracket-${i}`} aria-hidden>
                <span>{range}</span>
              </div>
            }
            <div className="bc-bar-items-container">
              {
                splitIconNameArr[i].map((iconName: string): React.Element<'div'> => {
                  counter += 1;
                  const index = counter;
                  const mappedIconName = content.methodNames[iconName];

                  // this is purposely made not a button because we don't want screen reader users to interact with it
                  return (
                    <div
                      className={`bc-bar-item ${iconName}`}
                      key={iconName}
                      onClick={() => { clickHandler(index); }}
                      onKeyPress={() => { clickHandler(index); }}
                      type="dialog"
                      disabled={isAnyModalVisible}
                      aria-hidden={isAnyModalVisible}
                      tabIndex={isAnyModalVisible ? null : '0'}
                      ref={mobileButtonRefs[index]}
                    >
                      <div className="screen-reader-only">{ MarkdownSyntaxRemoverFunc(hyphenReplacerFunc(`${mappedIconName}: ${resultArr[i]}`, locale)) }</div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};
