// @flow

import * as React from 'react';
import { QuestionText } from '../textComponents/TextComponents';

type MultiPartSurveyQuestionProps = CommonSurveyQuestionProps & {
  texts: Array<string>,
  choices: Array<string>,
  choiceKeys?: Array<string>,
  questionKey?: string,
  currentChoice: null | Array<number | null>,
  clickHandler: (string, string, string) => void,
  subkeys: Array<string>,
  categories: Array<string>,
};

const MultiPartSurveyQuestion = ({
  texts,
  ariaHidden,
  categories,
  choices,
  subkeys,
  questionKey,
  choiceKeys,
  clickHandler,
  currentChoice,
}: MultiPartSurveyQuestionProps): React.Element<'div'> => (
  <form aria-hidden={ariaHidden}>
    <div className="survey-question-container multi" role="group" aria-labelledby={`question-${questionKey}`}>
      <QuestionText textArr={texts} id={`question-${questionKey}`} />
      <div className="survey-categories-container" >
        {
          categories.map((category: string, categoryIndex: number): React.Element<'div'> => (
            <div className="survey-category-container" key={category} role="group" aria-labelledby={`category-${category}`}>
              <h3 className="category-name" id={`category-${category}`}>{category}</h3>
              {
                choices.map((choice: string, choiceIndex: number): React.Element<'button'> => {
                  let active = false;
                  if (currentChoice && currentChoice[subkeys[categoryIndex]] === choiceKeys[choiceIndex]) {
                    active = true;
                  }

                  return (
                    <div
                      role="radio"
                      aria-label={choice}
                      className={`survey-choice ${active ? 'active' : ''}`}
                      aria-checked={active}
                      key={choice}
                      tabIndex={ariaHidden ? null : '0'}
                      onClick={() => {
                        clickHandler(questionKey, subkeys[categoryIndex], choiceKeys[choiceIndex]);
                      }}
                      onKeyPress={() => {
                        clickHandler(questionKey, subkeys[categoryIndex], choiceKeys[choiceIndex]);
                      }}
                    >
                      {choice}
                    </div>
                  );
                })
              }
            </div>
          ))
        }
      </div>
    </div>
  </form>
);

export default MultiPartSurveyQuestion;
