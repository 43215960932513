// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './Survey4MBC.scss';

export const questionConfig4 = [
  {
    type: 'multi',
    // prefill: 'never',
    questionKey: 'feelAboutMethods',
    subkeys: ['Sterilization', 'Hormonal IUD', 'Copper IUD', 'Implant', 'Shot', 'Ring', 'Patch', 'Pill', 'Diaphragm', 'Male Condom', 'Internal Condom'],
    choiceKeys: ['like', 'neutral', 'negative', 'never'],
  },
];

// const Survey4MBC = (props: SurveyMBCProps): React.Element<*> => {
class Survey4MBC extends React.Component<SurveyMBCProps> {
  componentDidMount() { this.props.prefillSurvey4(); }

  render() {
    const {
      locale, navLinks, surveyStateMBC, answerSurveyMultiMBC, isAnyModalVisible,
      content: { headings, paragraphs, notes, navigation, survey4MbcQuestions }
    } = this.props;
    const isNextButtonHidden = !questionConfig4.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));
    const commonQuestionPropsArr = questionConfig4.map((config: Object, index: number): Object => ({ ...config, ...survey4MbcQuestions[index] }));

    return (
      <>
        <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
        <div className="survey-questions-container">
          <MultiPartSurveyQuestion
            clickHandler={answerSurveyMultiMBC}
            currentChoice={surveyStateMBC[questionConfig4[0].questionKey]}
            questionIndex={8}
            ariaHidden={isAnyModalVisible}
            MBC
            {...commonQuestionPropsArr[0]}
          />
        </div>
        <NavContainer
          locale={locale}
          isHidingNextButton={isNextButtonHidden}
          nextPage={navLinks[1]}
          notesContent={notes}
          navigationContent={navigation}
        />
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMBC }: State): Object => ({ surveyStateMBC });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey4MBC);
