// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import MenuItem from '../../shared/menuItem/MenuItem';
import afterBabyActions from '../../../actions/afterBabyActions';
import type { PageProps } from '../../../lib/types';
import './SexAfterBaby.scss';

type SexAfterBabyProps = PageProps & {
  afterBabyTopics: {},
};

type State = {
  visibleModal: string,
};

class SexAfterBaby extends React.Component<SexAfterBabyProps, State> {
  state = { visibleModal: null };

  showModal = (item: string) => {
    const { onModalShow } = this.props;
    this.setState({ visibleModal: item });
    if (onModalShow) { onModalShow(item); }
  };

  hideModal = () => {
    this.setState({ visibleModal: null });
  }

  render() {
    const { content, locale, afterBabyTopics, isAnyModalVisible } = this.props;
    const { visibleModal } = this.state;

    const menuItemProps = (item: string): {} => ({
      question: content[`${item}_topic`],
      modalCopy: content[`${item}_modal_copy`],
      modalVisible: visibleModal === item,
      ariaHidden: isAnyModalVisible,
      onModalShow: () => { this.showModal(item); },
      onModalHide: this.hideModal,
      visited: !!afterBabyTopics[item],
      buttonText: content.buttonText,
      content,
      locale,
    });

    return (
      <>
        <Headings ariaHidden={isAnyModalVisible} isAutoFocusing str={content.headings} />
        <MarkdownBlock ariaHidden={isAnyModalVisible} str={content.paragraphs} />
        <div className="menu-container">
          <MenuItem {...menuItemProps('partner_talk')} className="first" />
          <MenuItem {...menuItemProps('listen_body')} className="third" />
          <MenuItem {...menuItemProps('real_talk')} className="fourth" />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ afterBabyTopics }: StateType): State => ({ afterBabyTopics });

const mapDispatchToProps = afterBabyActions;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SexAfterBaby);
