// @flow

import * as React from 'react';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import type { PageProps } from '../../../lib/types';
import './FertilityFuture.scss';

const FutilityFuture = ({ content, isAnyModalVisible }: PageProps): React.Element<*> => (
  <>
    <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
    <div aria-hidden className="image-space" />
    <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
  </>
);

export default FutilityFuture;
