// @flow
import type { Action } from '../lib/types';

const initialState = {};

export default (state: {} = initialState, action: Action): Array<string> => {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case 'VISIT_AFTER_BABY_TOPIC':
      newState[action.item] = true;
      return newState;
    default:
      return state;
  }
};
