// @flow

import * as React from 'react';
import markdown, { hyphenReplacerFunc } from '../../../lib/utils';
import './TextComponents.scss';

type HeadingsProps = {
  str: string,
  externalHeading1Ref?: * => void,
  isAutoFocusing?: boolean,
  ariaHidden?: boolean,
  id?: string | null,
};

type ParagraphsProps = {
  paragraphArr: Array<string>,
};

type MarkdownBlockProps = {
  str: string,
  ariaHidden: boolean,
  contentRef: HTMLElement => void,
  id?: string | null
};

type TDProps = {
  str: string | null,
  locale: string,
};

type QuestionTextProp = {
  textArr: Array<string>,
  id: string,
};

export const QuestionText = ({ textArr, id }: QuestionTextProp): React.Node => (
  <>
    {
      textArr.map((str: string, i: number, arr: [string]): React.Element<'div'> => (
        <div
          className="question-text"
          key={str}
          id={i === arr.length - 1 ? id : null}
          dangerouslySetInnerHTML={{ __html: markdown(str) }}
        />
      ))
    }
  </>
);

export class Headings extends React.Component<HeadingsProps> {
  static defaultProps = {
    externalHeading1Ref: undefined,
    isAutoFocusing: false,
    ariaHidden: false,
    id: null
  };

  componentDidMount() {
    const { isAutoFocusing } = this.props;
    if (isAutoFocusing) {
      this._heading1.focus();
    }
  }

  internalHeading1Ref = (c: ?HTMLHeadingElement) => { this._heading1 = c; }

  handleTouchPress = () => {
    this.touchTimer = setTimeout(() => {
      if (!(/mypath/g).test(process.env.REACT_APP_MODE)) window.location.pathname = '/';
    }, 3000);
  }

  handleTouchRelease = () => {
    clearTimeout(this.touchTimer);
  }


  render() {
    const { str, externalHeading1Ref, isAutoFocusing, ariaHidden, id } = this.props;
    const refFunc = isAutoFocusing ? this.internalHeading1Ref : externalHeading1Ref;
    // console.log('refFunc', refFunc, this)

    return (
      <div
        aria-hidden={ariaHidden}
        className="heading-container focus-start"
        ref={refFunc}
        onTouchStart={this.handleTouchPress}
        onTouchCancelCapture={this.handleTouchRelease}
        dangerouslySetInnerHTML={{ __html: markdown(str) }}
        tabIndex={ariaHidden ? null : '-1'}
        id={id}
      />
    );
  }
}

export const MarkdownBlock = ({ id = null, str, contentRef, ariaHidden = false }: MarkdownBlockProps): React.Element<"div"> => (
  <div
    id={id}
    ref={contentRef}
    tabIndex={ariaHidden ? null : '-1'}
    className="markdown-block focus-start"
    aria-hidden={ariaHidden}
    dangerouslySetInnerHTML={{ __html: markdown(str) }}
  />
);

export const SpanParagraphs = ({ paragraphArr }: ParagraphsProps): React.Element<'div'> => (
  <>
    { paragraphArr.map((str: string): React.Element<'span'> => (
      <span className="span-paragraph" key={str} dangerouslySetInnerHTML={{ __html: markdown(str) }} />
    )) }
  </>
);

const TDmarkdownSanitizer = (str: string): string => str.replace(/\+|\\n/gi, '');

export const TD = ({ str, locale }: TDProps): React.Element<'td'> => (
  str !== null ? (
    <td className="table-td active">
      <span className="screen-reader-only">
        { TDmarkdownSanitizer(hyphenReplacerFunc(str, locale)) }
      </span>
      <span dangerouslySetInnerHTML={{ __html: markdown(str) }} aria-hidden />
    </td>
  ) : <td className="table-td" />
);

export const LI = ({ str }: { str: string }): React.Element<'td'> => (
  <li className="list-item" dangerouslySetInnerHTML={{ __html: markdown(`&ndash; ${str}`) }} />
);
