// @flow

import * as React from 'react';

class MyReproductiveHealthJourney extends React.Component<> {
  componentDidMount() { this._logo.focus(); }

  logoRef = (c: HTMLDivElement) => { this._logo = c; };

  render() {
    return (
      <div
        className="my-reproductive-health-journey focus-start focus-start"
        ref={this.logoRef}
        tabIndex="-1"
      >
        <p className="screen-reader-only">My Path. My Reproductive Health Journey.</p>
      </div>
    );
  }
}

export default MyReproductiveHealthJourney;
