// @flow

import type { Action, FertilityMenuState } from '../lib/types';

const initialState = { visited: [] };

const fertilityMenuReducer = (state: FertilityMenuState = initialState, action: Action): FertilityMenuState => {
  switch (action.type) {
    case 'SET_FERTILITY_ITEM_VISITED':
      if (!state.visited.includes(action.item)) {
        return Object.assign({}, state, { visited: [...state.visited, action.item] });
      }
      return state;
    default:
      return state;
  }
};

export default fertilityMenuReducer;
