// @flow

import * as React from 'react';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import Topic from '../../shared/topic/Topic';
import type { PageProps } from '../../../lib/types';
import './HealthPregnancyTopics.scss';

export default class HealthPregnancyTopics extends React.PureComponent<PageProps> {
  render() {
    const { content, isAnyModalVisible } = this.props;

    return (
      <div className="HealthPregnancyMenu">
        <Headings
          isAutoFocusing
          str={content.headings}
          ariaHidden={isAnyModalVisible}
        />
        <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
        <div className="topics">
          {content.topics.map((topic: string): React.Element<*> => (
            <Topic topic={topic} key={topic} ariaHidden={isAnyModalVisible} />
          ))}
        </div>
        <MarkdownBlock str={content.bottomText} ariaHidden={isAnyModalVisible} />
      </div>
    );
  }
}
