// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import currentMethodsActions from '../../../actions/currentMethodsActions';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import NavContainer from '../../shared/nav/NavContainer';
import type { PageProps, MapStateToProps, State, Dispatch } from '../../../lib/types';
import './CurrentMethods.scss';

type CurrentMethodsProps = PageProps & {
  currentMethods: Array<string>,
  toggleCurrentMethod: Dispatch,
};

const methods = [
  'hormonalIud',
  'copperIud',
  'implant',
  'shot',
  'ring',
  'patch',
  'pill',
  'diaphragm',
  'maleCondom',
  'femaleCondom',
  'cervicalCap',
  'fertilityAwareness',
  'sponge',
  'spermicide',
  'withdrawal',
  'sterilization',
  'notRightNow',
  'emergencyContraception',
  'other',
  'notUsingAnyMethod'
];

const CurrentMethods = ({ locale, content, currentMethods, toggleCurrentMethod, navLinks, isAnyModalVisible }: CurrentMethodsProps): React.Element<*> => {
  const { headings, paragraphs } = content;
  const isNextButtonHidden = currentMethods.size === 0;

  return (
    <>
      <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock id="title-block" str={paragraphs} ariaHidden={isAnyModalVisible} />
      <div className="icon-container" role="group" aria-labelledby="title-block" aria-hidden={isAnyModalVisible}>
        {
          methods.map((name: string): React.Element<'button'> => {
            const isActive = currentMethods.includes(name);
            const extraClasses = `${name} ${isActive ? 'active' : ''}`;

            return (
              <div className="icon-button" key={name}>
                <div
                  aria-checked={isActive}
                  role="checkbox"
                  tabIndex={isAnyModalVisible ? null : 0}
                  aria-label={content.methodNames[name]}
                  onClick={() => { toggleCurrentMethod(name); }}
                  onKeyPress={() => { toggleCurrentMethod(name); }}
                  className={`icon ${extraClasses}`}
                  id={`checkbox-${name}-box`}
                />
                <label id={`checkbox-for-${name}`} aria-hidden>{content.methodNames[name]}</label>
              </div>
            );
          })
        }
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        backPage={navLinks[0]}
        notesContent={content.notes}
        navigationContent={content.navigation}
      />
    </>
  );
};

const mapDispatchToProps = currentMethodsActions;
const mapStateToProps: MapStateToProps<State> = ({ currentMethods }: State): Object => ({ currentMethods });


export default connect(mapStateToProps, mapDispatchToProps)(CurrentMethods);
