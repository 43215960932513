// @flow

import * as React from 'react';
import SilhouetteInterface from '../../shared/silhouetteInterface/SilhouetteInterface';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PagePropsWithBcEnglishNames } from '../../../lib/types';
import './HowToWomen.scss';

const howToWomenConfig = {
  pill: ['up', 'top', 'left'],
  implant: ['up', 'top', 'left'],
  patch: ['down', 'bottom', 'left'],
  iud: ['down', 'bottom', 'left'],
  diaphragm: ['down', 'bottom', 'left'],
  shot: ['up', 'top', 'right'],
  sterilization: ['up', 'top', 'right'],
  ring: ['down', 'bottom', 'right'],
  femaleCondom: ['down', 'bottom', 'right'],
};

const methods = [
  'pill',
  'implant',
  'patch',
  'iud',
  'diaphragm',
  'shot',
  'sterilization',
  'ring',
  'femaleCondom',
];

const HowToWomen = (props: PagePropsWithBcEnglishNames): React.Element<*> => {
  const { content, isAnyModalVisible } = props;
  const mappedIconNames = methods.map((str: string): string => content.methodNames[str]);

  return (
    <>
      <div className="flexbox-group">
        <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
      </div>
      <SilhouetteInterface
        config={howToWomenConfig}
        iconNamesEnglish={methods}
        iconNames={mappedIconNames}
        iconExplanations={content.explanations}
        {...props}
      />
    </>
  );
};

export default HowToWomen;
