// @flow

import * as React from 'react';
import type { PageProps } from '../../../lib/types';
import './IUDinfo.scss';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import iudInfoImage from './iud_info_background.jpg';

const IUDinfo = ({ content, isAnyModalVisible }: PageProps): React.Element<*> => (
  <>
    <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
    <div className="flex-group">
      <img src={iudInfoImage} className="iud-info-image" alt="" aria-hidden />
      <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
    </div>
  </>
);

export default IUDinfo;
