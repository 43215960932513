// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Brackets, IconBar, ResultBar, MobileBracketsAndIconBars } from '../../shared/IconBarParts';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import EggArray from '../../shared/eggArray/EggArray';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import type { PageProps } from '../../../lib/types';
import '../../shared/IconArrayPage.scss';
import './Effectiveness.scss';

type EffectivenessState = {
  selectedIconNum: number | null,
};

type EffectivenessProps = PageProps & {
  setModalInvisible: () => {},
  setModalVisible: () => {},
};

const methods = [
  'noMethod',
  'femaleCondom',
  'maleCondom',
  'diaphragm',
  'pill',
  'patch',
  'ring',
  'shot',
  'implant',
  'iud',
  'sterilization',
];

const mobileConfig = [4, 4, 3];

const rangeesToBeReadConfig = [0, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3];

class Effectiveness extends React.Component<EffectivenessProps, EffectivenessState> {
  _modalParagraph: HTMLParagraphElement;

  constructor(props: EffectivenessProps) {
    super(props);
    this.state = { selectedIconNum: null };
    this.modalParagraphRef = (c: HTMLHeadingElement) => { this._modalParagraph = c; };
    this.mobileButtonRefs = [];
    methods.forEach((_: number, i: number) => {
      this.mobileButtonRefs[i] = React.createRef();
    });
  }


  componentDidMount() {
    if (window.innerWidth > 720) setTimeout(() => { this.setState({ selectedIconNum: 0 }); }, 10);
  }

  bcIconClickHandler = (num: number | null, mode: string) => {
    const { setModalInvisible, setModalVisible } = this.props;
    const { selectedIconNum: prevSelectedIconNum } = this.state;
    this.setState({ selectedIconNum: num });

    if (mode === 'mobile') {
      if (num === null) {
        setModalInvisible();
        setTimeout(() => { this.mobileButtonRefs[prevSelectedIconNum].current.focus(); }, 10);
      } else {
        setModalVisible();
        setTimeout(() => { this._modalParagraph.focus(); }, 10);
      }
    }
  }

  render() {
    const { content, isAnyModalVisible, locale } = this.props;
    const { selectedIconNum } = this.state;
    const resultNum = selectedIconNum === null ? 0 : content.bcNumbers[selectedIconNum];
    const isModalOn = selectedIconNum !== null;
    const heading = selectedIconNum === null ? '' : `# ${content.methodNames[methods[selectedIconNum]]}`;
    const fullParagraph = selectedIconNum === null ? '' : content.bcResults[selectedIconNum];

    const commonPropsForIconResult = {
      iconNameArr: methods,
      selectedIconNum,
      isAnyModalVisible,
      content,
    };

    const rangesToBeRead = ['', ...content.ranges];
    const resultArrWithRanges = content.bcResults.map((result: str, i: number): str => `${result} ${rangesToBeRead[rangeesToBeReadConfig[i]]}`);

    return (
      <>
        <div className="bc-plain-texts-container" aria-hidden={isAnyModalVisible}>
          <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
          <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
          <h2 className="screen-reader-only effective-text" aria-hidden={isAnyModalVisible}>{content.hiddenText}</h2>
        </div>
        <div className="bc-bars-and-brackets-container regular">
          <Brackets rangeArr={content.ranges} />
          <IconBar
            clickHandler={this.bcIconClickHandler}
            locale={locale}
            resultArr={resultArrWithRanges}
            {...commonPropsForIconResult}
          />
          <ResultBar
            resultArr={content.bcResults}
            {...commonPropsForIconResult}
          />
          <EggArray num={resultNum === 0 ? 1 : resultNum} />
        </div>
        <MobileBracketsAndIconBars
          rangeArr={content.ranges}
          clickHandler={(num: number | null) => { this.bcIconClickHandler(num, 'mobile'); }}
          mobileConfig={mobileConfig}
          mobileButtonRefs={this.mobileButtonRefs}
          resultArr={resultArrWithRanges}
          locale={locale}
          {...commonPropsForIconResult}
        />
        <div className="mobile mobile-graphics-container">
          <ModalWithOverlay
            isModalOn={isModalOn}
            clickHandler={() => { this.bcIconClickHandler(null, 'mobile'); }}
            closeBox={content.closeBox}
          >
            <Headings externalHeading1Ref={this.modalParagraphRef} str={heading} />
            <MarkdownBlock str={fullParagraph} />
            <EggArray num={resultNum === 0 ? 1 : resultNum} />
          </ModalWithOverlay>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(null, mapDispatchToProps)(Effectiveness);
