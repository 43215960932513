// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import type { PageProps } from '../../../lib/types';
import './Considerations.scss';

const methods = [
  'femaleCondom',
  'maleCondom',
  'diaphragm',
  'pill',
  'patch',
  'ring',
  'shot',
  'implant',
  'copperIud',
  'hormonalIud',
  'sterilization',
];

class Considerations extends React.Component<PageProps, State> {
  constructor(props: Props) {
    super(props);
    this.state = { activeNum: null };
    this.modalHeaderRef = React.createRef();
    this.buttonRefs = [];
    methods.forEach((_: number, i: number) => {
      this.buttonRefs[i] = React.createRef();
    });
  }

  iconClickHandler = (activeNum: number) => {
    const { onModalShow } = this.props;

    this.setState({ activeNum }, () => {
      this.modalHeaderRef.current.focus();
    });
    onModalShow();
  }

  resetActiveNumHandler = () => {
    const { onModalHide } = this.props;
    const { activeNum } = this.state;

    this.setState({ activeNum: null }, () => {
      this.buttonRefs[activeNum].current.focus();
    });
    onModalHide();
  }

  render() {
    const { content, isAnyModalVisible } = this.props;
    const { activeNum } = this.state;
    const isModalOn = activeNum !== null;
    const iconNames = methods.map((str: string): string => content.methodNames[str]);
    const methodName = content.methodNames[methods[activeNum]];

    return (
      <>
        <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
        <div className="icon-container">
          {
            methods.map((name: string, i: number): React.Element<'button'> => (
              <button
                ref={this.buttonRefs[i]}
                className={`icon-button ${name}`}
                key={name}
                onClick={() => { this.iconClickHandler(i); }}
                type="button"
                disabled={isModalOn}
                aria-hidden={isModalOn}
              >
                <p>{iconNames[i]}</p>
              </button>
            ))
          }
        </div>
        <ModalWithOverlay
          clickHandler={this.resetActiveNumHandler}
          isModalOn={isModalOn}
          closeBox={content.closeBox}
        >
          {isModalOn && (
            <div className="modal-content">
              <div className="modal-heading-container">
                <div className={`${methods[activeNum]} heading-background`} />
                <h1 ref={this.modalHeaderRef} className="focus-start" tabIndex="-1">{ methodName }</h1>
              </div>
              <MarkdownBlock str={content[`${methods[activeNum]}_modal_copy`]} />
            </div>
          )}
        </ModalWithOverlay>
      </>
    );
  }
}


const mapDispatchToProps = {
  onModalShow: (): Object => ({
    type: 'SET_IS_ANY_MODAL_VISIBLE',
    visibility: true
  }),
  onModalHide: (): Object => ({
    type: 'SET_IS_ANY_MODAL_VISIBLE',
    visibility: false
  }),
};

export default connect(
  null,
  mapDispatchToProps,
)(Considerations);
