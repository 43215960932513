// @flow

import React from 'react';
import { connect } from 'react-redux';
import './Instruction.scss';

type Props = {
  onCloseClick: () => void,
  isAnyModalVisible: boolean,
  visible: boolean,
  header: ?string,
  body: string,
};

const Instruction = ({ visible = true, header, body, onCloseClick, isAnyModalVisible }: Props): React.Node => {
  if (!visible) return null;

  return (
    <div id="instructions-container" aria-hidden>
      <div className="instructions-head">{header}</div>
      <div className="instructions-body">{body}</div>
      <div className="instructions-arrow" />
      <div
        disabled={isAnyModalVisible}
        className="instructions-close"
        onClick={onCloseClick}
        onKeyPress={onCloseClick}
        tabIndex={isAnyModalVisible ? null : '0'}
      />
    </div>
  );
};

const mapStateToProps = ({ isAnyModalVisible }: {}): {} => ({ isAnyModalVisible });

export default connect(mapStateToProps)(Instruction);
