// @flow

import type { Action, TopicState } from '../lib/types';

const initialState = { topics: [] };

const prefilledInitialState = {
  topics: [
    'VA maternity care',
    'Finances or housing',
    'Family history of medical or mental health problems',
    'Safety concerns about exposure from military service or current workplace'
  ]
};

const topicReducer = (state: TopicState = initialState, action: Action): TopicState => {
  switch (action.type) {
    case 'TOGGLE_TOPIC':
      if (state.topics.includes(action.topic)) {
        return Object.assign({}, state, { topics: state.topics.filter((topic: string): boolean => topic !== action.topic) });
      }
      return Object.assign({}, state, { topics: [...state.topics, action.topic] });
    case 'SECRET_PREFILL_TOPICS':
      return prefilledInitialState;
    default:
      return state;
  }
};

export default topicReducer;
