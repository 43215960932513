// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import sideEffectsAction from '../../../actions/sideEffectsActions';
import type { State, MapStateToProps, Dispatch } from '../../../lib/types';

type Props = {
  categoryNum: number | null,
  categoryNames: Array<string>,
  clickHandler: number => void,
  categoryVisit: Dispatch,
  sideEffectsCategoryVisitedArr: Array<boolean>,
  prevCategoryNum: null | number,
  mobileButtonLabel?: string,
};

class CategoryBar extends React.Component<Props> {
  static defaultProps = { mobileButtonLabel: '' };

  CategoryButton1Ref = (c: ?HTMLButtonElement) => { this._categoryButton1 = c; }
  CategoryButton2Ref = (c: ?HTMLButtonElement) => { this._categoryButton2 = c; }
  CategoryButton3Ref = (c: ?HTMLButtonElement) => { this._categoryButton3 = c; }

  componentDidMount() {
    const { prevCategoryNum } = this.props;
    if (Number.isInteger(prevCategoryNum)) this[`_categoryButton${prevCategoryNum + 1}`].focus();
  }

  render() {
    const {
      categoryNum,
      categoryNames,
      clickHandler,
      categoryVisit,
      sideEffectsCategoryVisitedArr,
      mobileButtonLabel,
    } = this.props;

    return (
      <div className="category-bar" role="tablist">
        {
          categoryNames.map((name: string, i: number): React.Element<'button'> => (
            <button
              aria-controls="category-intro-text"
              aria-label={`${name}, ${mobileButtonLabel}`}
              aria-selected={i === categoryNum}
              className={`selection-button ${i === categoryNum ? 'selected' : ''} ${sideEffectsCategoryVisitedArr[i] ? 'visited' : ''}`}
              key={name}
              onClick={() => { clickHandler(i); categoryVisit(i); }}
              ref={this[`CategoryButton${i + 1}Ref`]}
              role="tab"
              type="button"
            >
              {name}
            </button>
          ))
        }
      </div>
    );
  }
}

const mapDispatchToProps = sideEffectsAction;
const mapStateToProps: MapStateToProps<State> = ({ sideEffectsCategoryVisitedArr }: State): Object => ({ sideEffectsCategoryVisitedArr });

export default connect(mapStateToProps, mapDispatchToProps)(CategoryBar);
