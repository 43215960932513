// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MobileFakeRibbon, RegularFakeRibbon } from '../../shared/ribbon/Ribbon';
import NavButton from '../../shared/nav/NavButton';

type ConditionalNavProps = PageProps & {
  activeRecs: Array<string>,
  navLinks: Array<string>,
  isHidden: boolean,
  noMethodButtonRef: HTMLButtonElement => void,
  noMethodModalClickHandler: () => void,
};

const ConditionalNav = ({ noMethodButtonRef, isHidden, activeRecs, navLinks, noMethodModalClickHandler, content: { navigation, conditionalNavText } }: ConditionalNavProps): React.Element<'div'> => (
  <div className="nav-buttons-container">
    <MobileFakeRibbon
      to={navLinks[0]}
      str={navigation.compareMethodsText}
      isHidden={isHidden}
    />
    <RegularFakeRibbon
      to={navLinks[0]}
      str={navigation.compareMethodsText}
      isHidden={isHidden}
    />
    {
      activeRecs.length > 0 ? (
        <NavButton
          str={navigation.nextText}
          to={navLinks[1]}
          isHidden={isHidden}
        />
      ) : (
        <button
          className={`nav-button conditional ${isHidden ? 'no-display' : ''}`}
          aria-hidden={isHidden}
          onClick={noMethodModalClickHandler}
          type="button"
          ref={noMethodButtonRef}
          tabIndex={isHidden ? null : '0'}
        >
          {conditionalNavText}
        </button>
      )
    }
    
  </div>
);

const mapStateToProps2 = ({ activeRecs }: State): { activeRecs: Array<string> } => ({ activeRecs });
export default connect(mapStateToProps2)(ConditionalNav);
