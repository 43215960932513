// @flow

import * as React from 'react';
import NavButton from '../nav/NavButton';
import './Ribbon.scss';

type RibbonProps = {
  str: string,
  to: string,
  isHidden: boolean,
};

export const RegularRibbon = ({ str, to, isHidden }: RibbonProps): React.element<NavButton> => (
  <NavButton
    str={str}
    to={to}
    additionalClasses={`regular ribbon ${isHidden ? 'no-display' : ''}`}
  />
);

export const RegularFakeRibbon = ({ str, to, isHidden }: RibbonProps): React.element<NavButton> => (
  <NavButton
    str={str}
    to={to}
    additionalClasses={`regular fake-ribbon ${isHidden ? 'no-display' : ''}`}
  />
);

export const MobileFakeRibbon = ({ str, to, isHidden }: RibbonProps): React.element<NavButton> => (
  <NavButton
    str={str}
    to={to}
    additionalClasses={`mobile fake-ribbon ${isHidden ? 'no-display' : ''}`}
  />
);
