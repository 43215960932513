// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import NavButton from './NavButton';
import Note from '../note/Note';
import './NavContainer.scss';

type NavContainerProps = {
  backPage?: string,
  nextPage?: string,
  locale: string,
  isAnyModalVisible: boolean,
  isHidingNextButton?: boolean,
  hideNotes?: boolean,
  navigationContent: {
    nextText: string,
    backText: string,
  },
  notesContent?: {
    notesHeaderText?: string,
    notesShortDescription?: string,
    notesLongDescriptionFirst?: string,
    notesLongDescriptionSecond?: string,
    notesInstructionsHeader?: string,
    notesInstructionsBody?: string,
  },
};


const NavContainer = ({
  backPage,
  nextPage,
  locale,
  isHidingNextButton,
  hideNotes,
  isAnyModalVisible,
  notesContent,
  navigationContent,
}: NavContainerProps): React.Element<'div'> => (
  <div className="nav-buttons-container">
    {backPage ? <NavButton isHidden={isAnyModalVisible} str={navigationContent.backText} to={`/${locale}/${backPage}`} left /> : <div />}
    {!hideNotes && <Note content={notesContent} />}
    {nextPage && <NavButton isHidden={isHidingNextButton || isAnyModalVisible} str={navigationContent.nextText} to={`/${locale}/${nextPage}`} />}
  </div>
);


NavContainer.defaultProps = {
  backPage: '',
  nextPage: '',
  isHidingNextButton: false,
  hideNotes: false,
  notesContent: {
    notesHeaderText: '',
    notesShortDescription: '',
    notesLongDescriptionFirst: '',
    notesLongDescriptionSecond: '',
    notesInstructionsHeader: '',
    notesInstructionsBody: '',
  }
};

const mapStateToProps = ({ isAnyModalVisible }: StateType): State => ({ isAnyModalVisible });

export default connect(mapStateToProps)(NavContainer);
