// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import navActions from '../../../actions/navActions';
import type { Dispatch } from '../../../lib/types';
import MainNavArrowImgGreen from './main_nav_arrow_green.svg';
import MainNavArrowImgWhite from './main_nav_arrow_white.svg';
import MainNavCheckImgOrange from './main_nav_check_orange.svg';
import MainNavCheckImgWhite from './main_nav_check_white.svg';

type NavButtonProps = {
  str: string,
  to: string,
  isMainNavMBC?: boolean,
  isMainNavMP?: boolean,
  isVisited?: boolean,
  isWiggling?: boolean,
  isHidden?: boolean,
  left?: boolean,
  index?: number,
  style?: {},
  additionalClasses?: string,
  hiddenText: string | null,
  mainNavVisitMBC: Dispatch,
  mainNavVisitMP: Dispatch,
  linkRef: Object | null,
};

let MainNavArrowImg;
let MainNavCheckImg;

if (process.env.REACT_APP_MODE === 'mypathVA') {
  MainNavArrowImg = MainNavArrowImgGreen;
  MainNavCheckImg = MainNavCheckImgOrange;
} else {
  MainNavArrowImg = MainNavArrowImgWhite;
  MainNavCheckImg = MainNavCheckImgWhite;
}

const imgClassNameGenerator = (isVisited: boolean, isWiggling: boolean): string => {
  if (isVisited) return 'main-nav-check';
  if (isWiggling) return 'main-nav-arrow wiggle';
  return 'main-nav-arrow';
};

const divClassNameGenerator = (isHidden: boolean, isVisited: boolean, left: boolean, additionalClasses: string): string => {
  let output = 'nav-button';
  if (isHidden) output += ' hidden';
  else if (isVisited) output += ' visited';
  if (left) output += ' left';
  return `${output} ${additionalClasses}`;
};

const strGenerator = (str: string, isMainNavMBC: boolean, left: boolean): string => {
  if (left) return `« ${str}`;
  if (!isMainNavMBC) return `${str} »`;
  return str;
};

class NavButton extends React.Component<NavButtonProps> {
  static defaultProps = {
    isMainNavMBC: false,
    isMainNavMP: false,
    isVisited: false,
    isWiggling: false,
    left: false,
    index: 0,
    isHidden: false,
    style: null,
    additionalClasses: '',
  };

  clickHandler = () => {
    const {
      isMainNavMBC,
      isMainNavMP,
      mainNavVisitMBC,
      mainNavVisitMP,
      index
    } = this.props;
    if (isMainNavMBC === true) mainNavVisitMBC(index);
    else if (isMainNavMP === true) mainNavVisitMP(index);
  };

  render() {
    const {
      str,
      hiddenText,
      to,
      isMainNavMBC,
      isMainNavMP,
      isVisited,
      isWiggling,
      isHidden,
      linkRef,
      left,
      style,
      additionalClasses
    } = this.props;

    const linkProps = { to };
    if (linkRef) linkProps.innerRef = linkRef;
    const label = to.split('/').slice(-1);

    return (
      <div
        className={divClassNameGenerator(
          isHidden,
          isVisited,
          left,
          additionalClasses
        )}
        onClick={this.clickHandler}
        role="menuitem"
        aria-labelledby={`${label}-label`}
        onKeyPress={this.clickHandler}
        style={style}
      >
        <Link {...linkProps} id={`${label}-label`}>
          <p>{strGenerator(str, isMainNavMBC, left)}</p>
          {hiddenText ? <span className="screen-reader-only">{hiddenText}</span> : null}
          {
            (isMainNavMBC || isMainNavMP) ? (
              <img
                className={imgClassNameGenerator(isVisited, isWiggling)}
                alt={isVisited ? 'visited' : isWiggling ? 'next suggested topic' : 'unvisited'}
                src={isVisited ? MainNavCheckImg : MainNavArrowImg}
              />
            ) : null
          }
        </Link>
      </div>
    );
  }
}

const mapDispatchToProps = navActions;
export default connect(null, mapDispatchToProps)(NavButton);
