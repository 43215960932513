// @flow

import * as React from 'react';
import type { PageProps } from '../../../lib/types';
import IntroPage from '../../shared/IntroPage';
import './IntroMP.scss';

const IntroMP = (props: PageProps): React.Element<*> => (
  <IntroPage nextPage="main_nav_mp" {...props} />
);

export default IntroMP;
