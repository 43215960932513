// @flow

import React from 'react';
import { connect } from 'react-redux';
import NavButton from '../../shared/nav/NavButton';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import { numOfQuestionToUnlockMainNavs } from '../../../reducers/surveyReducerMP';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import type { State, MapStateToProps, SurveyStateMP, PageProps } from '../../../lib/types';
import '../../shared/main/MainPage.scss';
import './MainMP.scss';

type MainPropsMP = PageProps & {
  surveyStateMP: SurveyStateMP,
  visitedState: Array<boolean>,
  navigationVisibility: { visibility: boolean },
  setModalInvisible: () => void,
};

const isMainNavUnlocked = (arr: Array<null | number>): boolean => {
  if (arr.slice(0, numOfQuestionToUnlockMainNavs).every((answer: null | number): boolean => answer !== null)) return true;
  if (arr[2] === 0) return true;
  if (arr[2] === 3 && arr[4] !== null && arr[5] !== null) return true;
  return false;
};

class MainMP extends React.Component<MainPropsMP> {
  constructor(props: MainPropsMP) {
    super(props);
    this.headerRef = React.createRef();
    this.navRefs = props.content.navs.map((): Object => React.createRef());
  }

  componentDidMount() {
    const { visitedState, setModalInvisible } = this.props;
    if (visitedState.length === 0 && this.headerRef.currents) {
      this.headerRef.current.focus();
    } else if (this.navRefs[visitedState[visitedState.length - 1]]) {
      this.navRefs[visitedState[visitedState.length - 1]].current.focus();
    }
    setModalInvisible();
  }

  render() {
    const {
      content,
      locale,
      visitedState,
      surveyStateMP,
      navLinks,
      isAnyModalVisible
    } = this.props;

    return (
      <>
        <Headings
          externalHeading1Ref={this.headerRef}
          str={content.headings}
          ariaHidden={isAnyModalVisible}
        />
        <MarkdownBlock
          str={content.paragraphs}
          ariaHidden={isAnyModalVisible}
        />
        <div className="main-nav-buttons-container" role="menu">
          {content.navs.map(
            (nav: string, i: number): React$Element<*> | null => {
              if (i !== 0 && !isMainNavUnlocked(surveyStateMP)) return null;

              return (
                <NavButton
                  str={nav}
                  to={`/${locale}/${navLinks[i]}`}
                  key={nav}
                  isMainNavMP
                  index={i}
                  isVisited={visitedState.includes(i)}
                  isHidden={isAnyModalVisible}
                  linkRef={this.navRefs[i]}
                />
              );
            }
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<State> = ({ visitedStateMP, surveyStateMP }: State): Object => (
  { visitedState: visitedStateMP, surveyStateMP }
);
const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(mapStateToProps, mapDispatchToProps)(MainMP);
