// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SinglePartSurveyQuestion from '../../shared/survey/SinglePartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './Survey5MBC.scss';

type Survey5MBCState = {
  isHidingMigraineQuestion: boolean,
};

export const questionConfig5 = [
  { type: 'plural', questionKey: 'concerns', choiceKeys: ['bloodclots', 'bloodPressure', 'over35', 'smoking', 'migraine', 'none'] },
  { type: 'plural', questionKey: 'migraine', choiceKeys: ['brightlights', 'spots', 'numbness', 'language', 'weakness', 'none'] },
];

class Survey5MBC extends React.Component<SurveyMBCProps, Survey5MBCState> {
  state = { isHidingMigraineQuestion: true };

  customAnswerSurveyPluralMBC = (questionKey: string, value: string) => {
    // console.log('questionKey', questionKey, 'value', value)
    const { answerSurveyPluralMBC, surveyStateMBC } = this.props;
    const { isHidingMigraineQuestion } = this.state;
    if (questionKey === 'concerns' && value === 'migraine') {
      if (surveyStateMBC.concerns === null || !surveyStateMBC.concerns.includes('migraine')) this.setState({ isHidingMigraineQuestion: false });
      else this.setState({ isHidingMigraineQuestion: true });
    }
    if (questionKey === 'concerns' && value === 'none' && !isHidingMigraineQuestion) {
      this.setState({ isHidingMigraineQuestion: true });
    }
    answerSurveyPluralMBC(questionKey, value);
  }

  render() {
    const { isHidingMigraineQuestion } = this.state;
    const {
      locale, navLinks, surveyStateMBC, answerSurveyPluralMBC, isAnyModalVisible,
      content: { headings, paragraphs, notes, navigation, survey5MbcQuestions }
    } = this.props;
    const filteredQuestionConfig5 = isHidingMigraineQuestion ? questionConfig5.slice(0, -1) : questionConfig5;
    const isNextButtonHidden = !filteredQuestionConfig5.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));
    const commonQuestionPropsArr = questionConfig5.map((config: Object, index: number): Object => ({ ...config, ...survey5MbcQuestions[index] }));

    return (
      <>
        <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
        <div className="survey-questions-container">
          <SinglePartSurveyQuestion
            clickHandler={this.customAnswerSurveyPluralMBC}
            currentChoice={surveyStateMBC[questionConfig5[0].questionKey]}
            {...commonQuestionPropsArr[0]}
            questionIndex={9}
            ariaHidden={isAnyModalVisible}
            MBC
            specialNone
          />
          {
            (isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig5[0].questionKey]) && !isHidingMigraineQuestion) && (
              <SinglePartSurveyQuestion
                clickHandler={answerSurveyPluralMBC}
                currentChoice={surveyStateMBC[questionConfig5[1].questionKey]}
                {...commonQuestionPropsArr[1]}
                questionIndex={10}
                ariaHidden={isAnyModalVisible}
                MBC
                specialNone
              />
            )
          }
        </div>
        <NavContainer
          locale={locale}
          isHidingNextButton={isNextButtonHidden}
          nextPage={navLinks[1]}
          notesContent={notes}
          navigationContent={navigation}
        />
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMBC }: State): Object => ({ surveyStateMBC });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey5MBC);
