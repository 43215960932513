// @flow

export default {
  onSave: (notes: string): Object => ({
    type: 'SET_NOTES',
    notes,
  }),
  onShowNotes: (): Object => ({ type: 'SET_NOTES_INSTRUCTIONS_HIDDEN' }),
  onHideInstructions: (): Object => ({ type: 'SET_NOTES_INSTRUCTIONS_HIDDEN' }),
};
