// @flow

import * as React from 'react';
import type { PageProps } from '../../../lib/types';
import IntroPage from '../../shared/IntroPage';
import UCSFtoolsFooter from '../../shared/footers/UCSFtoolsFooter';
import './Intro.scss';

const Intro = (props: PageProps): React.Element<*> => (
  <IntroPage nextPage="main_nav" navInside {...props}>
    <UCSFtoolsFooter id="intro-footer" key="footer" />
  </IntroPage>
);

export default Intro;
