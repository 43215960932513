// @flow

import * as React from 'react';
import { QuestionText } from '../textComponents/TextComponents';

type SinglePartSurveyQuestionProps = CommonSurveyQuestionProps & {
  texts: Array<string>,
  ariaHidden: boolean,
  choices: Array<string>,
  choiceKeys?: Array<string>,
  questionKey?: string,
  questionIndex?: number,
  currentChoice: number | null | Array<number>,
  clickHandler: (string, string) => void | (number, number) => void,
  isVertical: boolean,
  specialNone?: boolean,
  MBC?: boolean,
};

const SinglePartSurveyQuestion = ({
  texts,
  ariaHidden,
  choices,
  questionIndex,
  MBC,
  questionKey,
  choiceKeys,
  clickHandler,
  currentChoice,
  isVertical = false,
  specialNone = false
}: SinglePartSurveyQuestionProps): React.Element<'div'> => (
  <form aria-hidden={ariaHidden}>
    <div className={`survey-question-container ${isVertical ? 'vertical' : ''}`} role="group" aria-labelledby={`question-${questionIndex}`}>
      <QuestionText textArr={texts} id={`question-${questionIndex}`} />
      <div className="survey-choices-container">
        {choices.map((choice: string, choiceIndex: number): React.Element<'label'> => {
          const key = `question-${questionIndex}-option-${choiceIndex}`;
          let selected = false;

          if (MBC && choiceKeys) {
            selected = currentChoice === choiceKeys[choiceIndex] || (
              Array.isArray(currentChoice)
              && currentChoice.includes(choiceKeys[choiceIndex])
            );
          } else {
            selected = currentChoice === choiceIndex;
          }

          if (
            specialNone
            && choiceKeys[choiceIndex] === 'none'
            && Array.isArray(currentChoice) && currentChoice.length === 0
          ) {
            selected = true;
          }

          return (
            <button
              type="button"
              role={specialNone ? 'checkbox' : 'radio'}
              className={`survey-choice ${selected ? 'active' : ''}`}
              aria-checked={selected}
              name={`question-${questionIndex}`}
              key={key}
              tabIndex={ariaHidden ? null : '0'}
              onClick={() => {
                if (MBC && questionKey && choiceKeys) clickHandler(questionKey, choiceKeys[choiceIndex]);
                else if (questionIndex !== undefined) clickHandler(questionIndex, choiceIndex);
              }}
            >
              {choice}
            </button>
          );
        })}
      </div>
    </div>
  </form>
);

export default SinglePartSurveyQuestion;
