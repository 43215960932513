// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SinglePartSurveyQuestion from '../../shared/survey/SinglePartSurveyQuestion';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';

export const questionConfig2 = [
  { type: 'single', questionKey: 'admin', choiceKeys: ['negative', 'neutral', 'positive'] },
  {
    type: 'multi',
    questionKey: 'adminEnthusiasms',
    subkeys: ['oral', 'topical', 'vaginal', 'injection', 'uterine', 'subcutaneous', 'surgical', 'penile'],
    choiceKeys: ['positive', 'neutral', 'negative'],
  },
];

const Survey2MBC = (props: SurveyMBCProps): React.Element<*> => {
  const {
    locale, navLinks, surveyStateMBC, answerSurveySingleMBC, answerSurveyMultiMBC, isAnyModalVisible,
    content: { headings, paragraphs, notes, navigation, survey2MbcQuestions }
  } = props;
  const isNextButtonHidden = !questionConfig2.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));
  const commonQuestionPropsArr = questionConfig2.map((config: Object, index: number): Object => ({ ...config, ...survey2MbcQuestions[index] }));

  return (
    <>
      <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
      <div className="survey-questions-container">
        <SinglePartSurveyQuestion
          clickHandler={answerSurveySingleMBC}
          currentChoice={surveyStateMBC[questionConfig2[0].questionKey]}
          questionIndex={4}
          ariaHidden={isAnyModalVisible}
          MBC
          {...commonQuestionPropsArr[0]}
        />
        {
          isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig2[0].questionKey]) && (
            <MultiPartSurveyQuestion
              clickHandler={answerSurveyMultiMBC}
              currentChoice={surveyStateMBC[questionConfig2[1].questionKey]}
              questionIndex={5}
              ariaHidden={isAnyModalVisible}
              MBC
              {...commonQuestionPropsArr[1]}
            />
          )
        }
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        notesContent={notes}
        navigationContent={navigation}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMBC }: State): Object => ({ surveyStateMBC });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey2MBC);
