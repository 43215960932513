// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './Survey3MBC.scss';

export const questionConfig3 = [
  {
    type: 'multi',
    questionKey: 'sideEffectBothers',
    subkeys: ['spotting', 'noPeriod', 'heavierPeriod', 'weightGain'],
    choiceKeys: ['positive', 'neutral', 'negativeWeak', 'negative']
  },
  {
    type: 'multi',
    questionKey: 'benefitEnthusiasms',
    subkeys: ['decreasedAcne', 'noPeriod', 'decreasedCramping', 'decreasedPeriod'],
    choiceKeys: ['negative', 'neutral', 'positiveWeak', 'positive']
  },
];

const Survey3MBC = (props: SurveyMBCProps): React.Element<*> => {
  const {
    locale, navLinks, surveyStateMBC, answerSurveyMultiMBC, isAnyModalVisible,
    content: { headings, paragraphs, notes, navigation, survey3MbcQuestions }
  } = props;
  const isNextButtonHidden = !questionConfig3.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));
  const commonQuestionPropsArr = questionConfig3.map((config: Object, index: number): Object => ({ ...config, ...survey3MbcQuestions[index] }));

  return (
    <>
      <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
      <div className="survey-questions-container">
        <MultiPartSurveyQuestion
          clickHandler={answerSurveyMultiMBC}
          currentChoice={surveyStateMBC[questionConfig3[0].questionKey]}
          questionIndex={6}
          ariaHidden={isAnyModalVisible}
          MBC
          {...commonQuestionPropsArr[0]}
        />
        {
          isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig3[0].questionKey]) && (
            <MultiPartSurveyQuestion
              clickHandler={answerSurveyMultiMBC}
              currentChoice={surveyStateMBC[questionConfig3[1].questionKey]}
              questionIndex={7}
              ariaHidden={isAnyModalVisible}
              MBC
              {...commonQuestionPropsArr[1]}
            />
          )
        }
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        notesContent={notes}
        navigationContent={navigation}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMBC }: State): Object => ({ surveyStateMBC });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey3MBC);
