// @flow

import React from 'react';
import markdown from '../../../lib/utils';

type Props = {
  title: string,
  rows: [string],
  emptyNotesCopy: string,
};

const Notes = (props: Props): React.Element<'div'> => {
  const { title, rows, emptyNotesCopy } = props;

  let noteClass = 'note';
  let body;
  if (rows) {
    body = props.rows[0].split('\n').filter((str: string): boolean => !!str).join('\n\n');
  } else {
    // body = 'Have questions for your provider? Tap “back” to get to the menu page and tap the notepad icon at the bottom of the screen to add questions.';
    body = emptyNotesCopy;
    noteClass += ' empty';
  }

  return (
    <div className="item notes">
      <h2>{title}</h2>
      <div className={noteClass} dangerouslySetInnerHTML={{ __html: markdown(body) }} />
    </div>
  );
};

export default Notes;
