// @flow

import * as React from 'react';
import './Rainbow.scss';

const Rainbow = (): React.Element<'header'> => (
  <header className="rainbow">
    <div className="yellow-box" />
    <div className="red-box" />
    <div className="lime-box" />
    <div className="green-box" />
    <div className="blue-box" />
    <div className="indigo-box" />
  </header>
);

export default Rainbow;
