// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import HealthPregnancyMenuItem from './HealthPregnancyMenuItem';
import type { pageProps } from '../../../lib/types';
import './HealthPregnancyMenu.scss';

const menuItems = [
  'healthy_lifestyle',
  'medical_conditions',
  'mental_health',
  'medications',
  'relationships',
  'healthy_weight',
  'infections',
  'spacing_between_babies',
];

type State = {
  visibleModal: string,
};

type Props = pageProps & {
  visitedItems: Array<string>,
  onModalShow: (item: string) => {},
  onModalHide: () => {},
};

class HealthPregnancyMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { visibleModal: null };
  }

  showModal = (item: string) => {
    const { onModalShow } = this.props;
    this.setState({ visibleModal: item });
    if (onModalShow) { onModalShow(item); }
  };

  hideModal = () => {
    const { onModalHide } = this.props;
    this.setState({ visibleModal: null });
    if (onModalHide) { onModalHide(); }
  }

  render() {
    const { content, visitedItems, isAnyModalVisible } = this.props;
    const { visibleModal } = this.state;

    return (
      <>
        <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
        <div className="items-container">
          {menuItems.map((key: string): Array<HealthPregnancyMenuItem> => (
            <HealthPregnancyMenuItem
              key={content[key].label}
              item={key}
              label={content[key].label}
              sections={content[key].sections}
              content={content}
              modalVisible={visibleModal === key}
              isAnyModalVisible={isAnyModalVisible}
              visited={visitedItems.includes(key)}
              onModalShow={(): void => this.showModal(key)}
              onModalHide={this.hideModal}
            />
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: State): StateType => (
  { visitedItems: state.healthPregnancyReducer.visited }
);

const mapDispatchToProps = (dispatch: Dispatch): Object => ({
  onModalShow: (item: string): null => {
    dispatch({ type: 'SET_HEALTH_PREGNANCY_ITEM_VISITED', item });
    dispatch({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: true });
  },
  onModalHide: (): null => {
    dispatch({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: false });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthPregnancyMenu);
