// @flow

import React from 'react';
import { connect } from 'react-redux';
import Instruction from '../note/Instruction';

import './Topic.scss';

import type { Action, State } from '../../../lib/types';

type Props = {
  selected: boolean,
  topic: string,
  shortForm: boolean, // true = don't include the sentence around topic, e.g. "added to my summary"
  showInstructions: boolean,
  onClick: () => void,
  onMount: () => void,
  onUnmount: () => void,
  onHideInstructions: () => void,
  style?: {},
  ariaHidden?: boolean,
};

class Topic extends React.Component<Props> {
  static defaultProps = {
    style: {},
    ariaHidden: false,
  }

  constructor(props: Props) {
    super(props);
    this.clickTopic = this.clickTopic.bind(this);
  }

  componentDidMount() {
    const { onMount, topic } = this.props;
    if (onMount) { onMount(topic); }
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    if (onUnmount) { onUnmount(); }
  }

  clickTopic() {
    const { onClick, topic, selected } = this.props;
    onClick(topic, selected);
  }

  render() {
    const { topic, selected, shortForm, onHideInstructions, showInstructions, ariaHidden } = this.props;
    let before = '';
    let after = '';
    if (!shortForm) {
      if (selected) {
        after = 'added to my summary';
      } else {
        before = 'Add';
        after = 'to my summary';
      }
    }

    return (
      <div className={`topic-container ${selected ? 'selected' : ''}`}>
        {showInstructions && (
          <Instruction
            body="Tap to add this to your summary page to discuss with your provider."
            key="instructions"
            styles={{ top: '-104px', bottom: 'auto' }}
            onCloseClick={onHideInstructions}
          />
        )}
        <div
          aria-checked={selected}
          role="checkbox"
          tabIndex={ariaHidden ? null : '0'}
          aria-hidden={ariaHidden}
          aria-label={`${before} ${topic} ${after}`}
          className="topic-button"
          onClick={this.clickTopic}
          onKeyPress={this.clickTopic}
          type="button"
        >
          {before} <em>{topic}</em> {after}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: Props): TopicState => (
  { selected: state.topicReducer.topics.includes(ownProps.topic) }
);

const mapDispatchToProps = {
  onClick: (topic: string): Action => {
    // const eventKey = selected ? `topic_${topic}_off` : `topic_${topic}_on`;
    // recordEvent(eventKey);
    return { type: 'TOGGLE_TOPIC', topic };
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Topic);
