// @flow

import * as React from 'react';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PageProps } from '../../../lib/types';
import './PregnantAgain.scss';

const PregnantAgainFirst = ({ content }: PageProps): React.Element<*> => (
  <>
    <Headings isAutoFocusing str={content.headings} />
    <div className="flex-group">
      <div className="image-space regular" />
      <MarkdownBlock str={content.paragraphs} />
    </div>
  </>
);

export default PregnantAgainFirst;
