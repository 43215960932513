// @flow

import React from 'react';
import markdown from '../../../lib/utils';

type Props = {
  title: string,
  rows: Array<Object>,
};

const SideEffectsBenefits = ({ title, rows }: Props): React.Element<'div'> => (
  (/mypath/g).test(process.env.REACT_APP_MODE) && rows.every(({ value }: {}): boolean => value === '')
    ? null : (
      <div className="item questions side_effects_benefits">
        <h2>{title}</h2>
        <ul>
          {
            rows.map(({ category, value }: Object): React.Element<'li'> => (
              <li key={category}>
                <div
                  className="question"
                  dangerouslySetInnerHTML={{ __html: markdown(category) }}
                />
                <div className="answer">
                  {value}
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    )
);

export default SideEffectsBenefits;
