// @flow

import * as React from 'react';
import NavButton from '../../shared/nav/NavButton';
import type { PageProps } from '../../../lib/types';
import FooterWith4Images from '../../shared/footers/FooterWith4Images';
import MyReproductiveHealthJourney from './MyReproductiveHealthJourney';
import './SplashMP.scss';

const SplashMP = ({ content }: PageProps): React.ChildrenArray<*> => (
  <>
    <div className="spacer-1" />
    <div className="flexbox-group">
      <MyReproductiveHealthJourney />
      <div className="nav-buttons-container">
        <NavButton
          str={content.MyPathSplash}
          hiddenText={content.hiddenNavText}
          to="/en/intro_mp"
          tabIndex="0"
        />
      </div>
    </div>
    <div className="spacer-2" />
    <FooterWith4Images id="" />
  </>
);

export default SplashMP;
