// @flow

import * as React from 'react';
import NavButton from '../../shared/nav/NavButton';
// import HelloBeautiful from './hello-beautiful@2x.png';
import UCSFtoolsFooter from '../../shared/footers/UCSFtoolsFooter';
import type { PageProps } from '../../../lib/types';
import './Splash.scss';

const Splash = ({ content, navLinks }: PageProps): React.ChildrenArray<*> => (
  <>
    <div className="spacer-1" />
    <div className="flexbox-group">
      <div className="hello-beautiful" />
      <div className="nav-buttons-container">
        <NavButton str={content.EnglishSplash} to={navLinks[0]} tabIndex="0" />
        <NavButton str={content.SpanishSplash} to={navLinks[1]} tabIndex="0" />
      </div>
    </div>
    <div className="spacer-2" />
    <UCSFtoolsFooter id="splash-footer" key="footer" />
  </>
);

export default Splash;
