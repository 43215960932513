// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SinglePartSurveyQuestion from '../../shared/survey/SinglePartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMP } from '../../../lib/utils';
import NavButton from '../../shared/nav/NavButton';
import Note from '../../shared/note/Note';
import SurveyActions from '../../../actions/SurveyActions';
import type { SurveyMPProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';

type SurveyMPState = {
  pageNum: number,
};

const questionRanges = [[0, 1, 2], [3, 4], [5, 6]];
const choppedQuetionRanges = [[0, 1, 2], [4], [5, 6]];
const lastPageNum = questionRanges.length - 1;

class SurveyMP extends React.Component<SurveyMPProps, SurveyMPState> {
  _heading1: ?HTMLHeadingElement;

  state = { pageNum: 0 }

  componentDidMount() { this._heading1.focus({ preventScroll: false }); }

  externalHeading1Ref = (c: ?HTMLHeadingElement) => { this._heading1 = c; }

  fakeBackClickHandler = () => {
    const { pageNum } = this.state;
    const { _heading1 } = this;
    this.setState({ pageNum: pageNum - 1 }, () => {
      _heading1.focus({ preventScroll: false });
    });
  }

  fakeNextClickHandler = () => {
    const { pageNum } = this.state;
    const { _heading1 } = this;
    this.setState({ pageNum: pageNum + 1 }, () => {
      _heading1.focus({ preventScroll: false });
    });
  }

  render() {
    const { pageNum } = this.state;
    const {
      locale, navLinks, surveyStateMP, answerSurveySingleMP, isAnyModalVisible,
      content: { headings, paragraphs, notes, navigation, mypathSurveyQuestions, hiddenText }
    } = this.props;

    const filteredQuestionRanges = surveyStateMP[2] === 3 ? choppedQuetionRanges : questionRanges;
    const isNextButtonHidden = !filteredQuestionRanges[pageNum].every((index: number): boolean => (surveyStateMP[index] !== null));

    // conditionally rendering
    const hiddenClass = isNextButtonHidden ? 'no-display' : '';
    const backNavigation = pageNum === 0 ? (
      <NavButton str={navigation.backText} to={`/${locale}/${navLinks[0]}`} left isHidden={isAnyModalVisible} />
    ) : (
      <button
        onClick={this.fakeBackClickHandler}
        disabled={isAnyModalVisible}
        aria-hidden={isAnyModalVisible}
        type="button"
        className="nav-button left"
      >
        {`« ${navigation.backText} `}
      </button>
    );

    const nextNavigation = pageNum === lastPageNum || surveyStateMP[2] === 0 ? (
      <NavButton str={navigation.nextText} to={`/${locale}/${navLinks[0]}`} isHidden={isNextButtonHidden || isAnyModalVisible} />
    ) : (
      <button
        onClick={this.fakeNextClickHandler}
        disabled={isNextButtonHidden || isAnyModalVisible}
        aria-hidden={isNextButtonHidden || isAnyModalVisible}
        type="button"
        className={`nav-button ${hiddenClass}`}
      >
        {`${navigation.nextText} »`}
      </button>
    );

    return (
      <>
        <Headings str={headings} externalHeading1Ref={this.externalHeading1Ref} ariaHidden={isAnyModalVisible} />
        {
          pageNum === 0 && (
            <>
              <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
              { hiddenText ? <h2 className="screen-reader-only" aria-hidden={isAnyModalVisible}>{hiddenText}</h2> : null }
              <div className="page-1 survey-questions-container">
                <SinglePartSurveyQuestion
                  questionIndex={0}
                  clickHandler={answerSurveySingleMP}
                  currentChoice={surveyStateMP[0]}
                  ariaHidden={isAnyModalVisible}
                  {...mypathSurveyQuestions[0]}
                />
                {
                  isQuestionFullyAnsweredFuncMP(surveyStateMP[0]) && (
                    <SinglePartSurveyQuestion
                      questionIndex={1}
                      clickHandler={answerSurveySingleMP}
                      currentChoice={surveyStateMP[1]}
                      texts={surveyStateMP[0] === 0 ? mypathSurveyQuestions[1].texts2 : mypathSurveyQuestions[1].texts1}
                      ariaHidden={isAnyModalVisible}
                      {...mypathSurveyQuestions[1]}
                    />
                  )
                }
                {
                  isQuestionFullyAnsweredFuncMP(surveyStateMP[1]) && (
                    <SinglePartSurveyQuestion
                      questionIndex={2}
                      clickHandler={answerSurveySingleMP}
                      currentChoice={surveyStateMP[2]}
                      ariaHidden={isAnyModalVisible}
                      isVertical
                      {...mypathSurveyQuestions[2]}
                    />
                  )
                }
              </div>
            </>
          )
        }
        {
          pageNum === 1 && (
            <div className="page-2 survey-questions-container">
              {
                surveyStateMP[2] !== 3 && (
                  <SinglePartSurveyQuestion
                    questionIndex={3}
                    clickHandler={answerSurveySingleMP}
                    currentChoice={surveyStateMP[3]}
                    ariaHidden={isAnyModalVisible}
                    isVertical
                    {...mypathSurveyQuestions[3]}
                  />
                )
              }
              {
                (isQuestionFullyAnsweredFuncMP(surveyStateMP[3]) || surveyStateMP[2] === 3) && (
                  <SinglePartSurveyQuestion
                    questionIndex={4}
                    clickHandler={answerSurveySingleMP}
                    currentChoice={surveyStateMP[4]}
                    ariaHidden={isAnyModalVisible}
                    isVertical
                    {...mypathSurveyQuestions[4]}
                  />
                )
              }
            </div>
          )
        }
        {
          pageNum === 2 && (
            <div className="page-3 survey-questions-container">
              <SinglePartSurveyQuestion
                questionIndex={5}
                clickHandler={answerSurveySingleMP}
                currentChoice={surveyStateMP[5]}
                ariaHidden={isAnyModalVisible}
                isVertical
                {...mypathSurveyQuestions[5]}
              />
              {
                (isQuestionFullyAnsweredFuncMP(surveyStateMP[5])) && (
                  <SinglePartSurveyQuestion
                    questionIndex={6}
                    clickHandler={answerSurveySingleMP}
                    currentChoice={surveyStateMP[6]}
                    ariaHidden={isAnyModalVisible}
                    isVertical
                    {...mypathSurveyQuestions[6]}
                  />
                )
              }
            </div>
          )
        }

        <div className="nav-buttons-container">
          {backNavigation}
          <Note content={notes} />
          {nextNavigation}
        </div>
      </>
    );
  }
}

export const mapStateToProps: MapStateToProps<State> = ({ surveyStateMP }: State): Object => ({ surveyStateMP });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyMP);
