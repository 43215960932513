// @flow

import * as React from 'react';
import { SpanParagraphs } from '../textComponents/TextComponents';
import xIcon from '../../../shared_images/x-icon.png';
import './InfoBox.scss';

type InfoBoxProps = {
  isActive: boolean,
  iconName: string,
  iconNameEnglish: string,
  iconExplanation: string,
  clickHandler: * => void,
  positionStr: string,
  isSpan: boolean,
  style: null | {},
  closeButton: string,
  categoryName: null | string,
};

const InfoBox = ({
  isActive,
  iconName,
  iconNameEnglish,
  iconExplanation,
  clickHandler,
  positionStr,
  isSpan = false,
  style = null,
  closeButton,
  categoryName,
}: InfoBoxProps): React.Element<'div'> => {
  const clickHandlerWithPreventDefault = (e: React.SyntheticEvent<HTMLButtonElement>) => { clickHandler(); e.stopPropagation(); };

  return !isSpan ? (
    <div
      className={`info-box ${iconNameEnglish} ${isActive ? positionStr : 'no-display'}`}
      style={style}
      aria-hidden
      aria-labelledby={`${categoryName}-${iconNameEnglish}-heading`}
      role="dialog"
    >
      <div className="info-box-up-arrow" />
      <div className="info-box-head" id={`${categoryName}-${iconNameEnglish}-heading`}>
        {iconName}
        <button onClick={clickHandler} type="button">
          {closeButton}
          <img src={xIcon} alt="" />
        </button>
      </div>
      <div className="info-box-body">
        <SpanParagraphs paragraphArr={[iconExplanation]} />
      </div>
      <div className="info-box-down-arrow" />
    </div>
  ) : (
    <span
      className={`info-box ${iconNameEnglish} ${isActive ? positionStr : 'no-display'}`}
      style={style}
      aria-hidden
    >
      <span className="info-box-up-arrow" />
      <span className="info-box-head">
        {iconName}
        <button onClick={clickHandlerWithPreventDefault} type="button">
          {closeButton}
          <img src={xIcon} alt="" />
        </button>
      </span>
      <span className="info-box-body">
        <SpanParagraphs paragraphArr={[iconExplanation]} />
      </span>
      <span className="info-box-down-arrow" />
    </span>
  );
};

export default InfoBox;
