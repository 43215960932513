// @flow
import type { Action, SurveyStateMP } from '../lib/types';

type SurveyAction = Action & { index: number };

export const numOfQuestionToUnlockMainNavs = 7;

const initialState = new Array(numOfQuestionToUnlockMainNavs + 1).fill(null);

const prefilledInitialState = new Array(numOfQuestionToUnlockMainNavs + 1).fill(1);

const surveyReducerMP = (state: SurveyStateMP = initialState, action: SurveyAction): SurveyStateMP => {
  const newAnswers = state.slice();

  switch (action.type) {
    case 'ANSWER_SURVEY_SINGLE_MP':
      newAnswers[action.index] = action.value;

      return newAnswers;
    case 'SECRET_PREFILL_SURVEY':
      return prefilledInitialState;
    default:
      return state;
  }
};

export default surveyReducerMP;
