// @flow

import * as React from 'react';
import SilhouetteInterface from '../../shared/silhouetteInterface/SilhouetteInterface';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PagePropsWithBcEnglishNames } from '../../../lib/types';
import './HowToMen.scss';

const howToMenConfig = {
  vasectomy: ['down', 'bottom', 'right'],
  maleCondom: ['up', 'bottom', 'left'],
};

const methods = [
  'maleCondom',
  'vasectomy',
];

const HowToMen = (props: PagePropsWithBcEnglishNames): React.Element<*> => {
  const { content, isAnyModalVisible } = props;
  const mappedIconNames = methods.map((str: string): string => content.methodNames[str]);

  return (
    <>
      <div className="flexbox-group">
        <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
      </div>
      <SilhouetteInterface
        config={howToMenConfig}
        iconNamesEnglish={methods}
        iconNames={mappedIconNames}
        iconExplanations={content.explanations}
        {...props}
      />
    </>
  );
};

export default HowToMen;
