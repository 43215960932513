// @flow

/*
  general structure and CSS animation based on this article
  https://www.smashingmagazine.com/2015/07/designing-simple-pie-charts-with-css/
*/

import React from 'react';

type Props = {
  chance: string,
  prefix: string,
  postfix: string,
  color: string,
  displayChance: ?string,
  ariaHidden: true,
};

const Pie = (props: Props): React$Element<*> => {
  const chartRadius = 100 / (2 * Math.PI); // this value makes the 100 strokeDash = 360 degrees
  const strokeWidth = 3.5;
  const borderWidth = 0.1;
  const outerRadius = chartRadius + ((strokeWidth + borderWidth) / 2);
  const innerRadius = chartRadius - ((strokeWidth + borderWidth) / 2);
  const centerLength = chartRadius + (strokeWidth / 2) + borderWidth;
  const viewBox = `0 0 ${centerLength * 2} ${centerLength * 2}`;
  const chanceToShow = props.displayChance ? props.displayChance : props.chance;
  const classNameWithColor = (clz, clr) => `${clz} ${clr}`;
  const chanceText = chance => `${chance}% chance`;

  const style = () => `
    @keyframes fillup${props.color} {
      from { stroke-dasharray: 0 100; }
      to { stroke-dasharray: ${props.chance} 100; }
    }
    .${props.color} .circle-graph {
      stroke-dasharray: ${props.chance} 100;
      animation: fillup${props.color} ${parseInt(props.chance, 10) * 10}ms linear 1;
    }
  `;

  return [
    <style type="text/css" key="style">{style()}</style>,
    <div className={classNameWithColor('pie-graph', props.color)} key="graph" aria-hidden={props.ariaHidden}>
      <svg viewBox={viewBox} className="pie">
        <circle style={{ strokeWidth: borderWidth }} className="outer-circle" r={outerRadius} cx={centerLength} cy={centerLength} />
        <circle style={{ strokeWidth }} className="circle-graph" r={chartRadius} cx={centerLength} cy={centerLength} />
        <circle style={{ strokeWidth: borderWidth }} className="inner-circle" r={innerRadius} cx={centerLength} cy={centerLength} />
      </svg>
      <p className="caption">
        <span dangerouslySetInnerHTML={{ __html: props.prefix }} />
        <span className="chance">{chanceText(chanceToShow)}</span>
        <span dangerouslySetInnerHTML={{ __html: props.postfix }} />
      </p>
    </div>,
  ];
};

export default Pie;
