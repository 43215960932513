// @flow

import * as React from 'react';

type IconButtonProps = {
  iconNameEnglish: string,
  iconName: string,
  isActive: boolean,
  clickHandler: string => void,
  newline?: boolean,
  children?: React.Node,
  ariaHidden: boolean,
  ariaText: string,
  sectionText: string,
};

const IconButton = ({
  ariaText = '',
  ariaHidden,
  iconNameEnglish,
  iconName,
  isActive = false,
  clickHandler,
  children = null,
  newline = false,
  sectionText
}: IconButtonProps): React.Element<'button'> => {
  const methodClassName = iconNameEnglish;
  const text = newline ? `${iconName}\n` : iconName;
  const extraClass = isActive ? 'active' : '';

  return (
    <div key={iconNameEnglish} className={`rec-icon-container ${extraClass}`} >
      <div
        aria-checked={isActive}
        role="checkbox"
        tabIndex={ariaHidden ? null : '0'}
        aria-label={`${text}, ${ariaText}`}
        onClick={() => { clickHandler(iconNameEnglish); }}
        onKeyPress={() => { clickHandler(iconNameEnglish); }}
        className={`icon ${methodClassName}`}
        id={`${sectionText}-checkbox-${methodClassName}-box`}
      />
      <label htmlFor={`checkbox-for-${methodClassName}-box`} id={`checkbox-for-${methodClassName}`} aria-hidden>
        {text}
        {children}
      </label>
    </div>
  );
};

IconButton.defaultProps = {
  children: null,
  newline: false,
};

export default IconButton;
