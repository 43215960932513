// @flow

import * as React from 'react';
import InfoBox from '../infoBox/InfoBox';
import './SilhouetteInterface.scss';

type SilhouetteInterfaceProps = {
  iconNamesEnglish: Array<string>,
  iconNames: Array<string>,
  iconExplanations: Array<string>,
  config: Object,
  toggleOff?: boolean,
  categoryName?: string,
  content: {},
  isAnyModalVisible: boolean,
};

type SilhouetteInterfaceState = { activeNum: number | null };

const iconStateGenerator = (activeNum: number | null, index: number): string => {
  if (activeNum === index) return 'color';
  if (activeNum === null) return 'normal';
  return 'dim';
};

class SilhouetteInterface extends React.Component<SilhouetteInterfaceProps, SilhouetteInterfaceState> {
  static defaultProps = {
    toggleOff: false,
    categoryName: '',
  };

  state = { activeNum: null };

  iconClickHandler = (num: number): null => {
    const { activeNum } = this.state;

    if (activeNum === num) this.setState({ activeNum: null });
    else this.setState({ activeNum: num });
  }

  closeClickHandler = (): null => { this.setState({ activeNum: null }); }

  render() {
    const {
      iconNamesEnglish,
      iconNames,
      iconExplanations,
      config,
      toggleOff,
      categoryName,
      content,
      isAnyModalVisible,
    } = this.props;

    const { activeNum } = this.state;

    return (
      <div className={`icon-column-container ${categoryName} ${toggleOff ? 'mobile-no-display' : ''}`}>
        {
          iconNamesEnglish.map((name: string, i: number): React.Element<'div'> => {
            const configArr = config[name] || [];
            const positionStr = `${configArr[0]} ${configArr[2]}`;
            const iconClassName = `${iconStateGenerator(activeNum, i)} icon-button`;

            // the interactive div is purposely made not a button because we don't want screen reader users to interact with it
            return (
              <div
                className={`${name} icon-and-map-container`}
                key={name}
              >
                <div
                  className={iconClassName}
                  role="dialog"
                  onClick={() => { this.iconClickHandler(i); }}
                  onKeyPress={() => { this.iconClickHandler(i); }}
                  aria-hidden={isAnyModalVisible}
                  tabIndex={isAnyModalVisible ? null : '0'}
                  aria-label={`${name}-${categoryName}`}
                >
                  <div className="screen-reader-only">
                    { `${iconNames[i]}: ${iconExplanations[i]}` }
                  </div>
                </div>
                <div className={`map ${configArr[1]} ${configArr[2]} ${i === activeNum ? '' : 'no-display'}`} />
                <InfoBox
                  isActive={i === activeNum}
                  iconName={iconNames[i]}
                  iconNameEnglish={name}
                  iconExplanation={iconExplanations[i]}
                  clickHandler={this.closeClickHandler}
                  positionStr={positionStr}
                  closeButton={content.closeButton}
                  categoryName={categoryName}
                />
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default SilhouetteInterface;
