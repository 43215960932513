// @flow

import * as React from 'react';
// import { Link } from 'react-router-dom';
import PoweredByBedsider from './powered-by-bedsider-logo@2x.png';
import UCSF from './ucsf-logo-text@2x.png';
import UW from './UW-logo@2x.png';
import VA from './VA-logo@2x.png';
import './FooterWith4Images.scss';

export default (props: {id: string}): React.Element<'footer'> => (
  <footer className="mbc-footer-mp" {...props}>
    <img className="va" src={VA} alt="US Department of Veterans Affairs" />
    <img className="uw" src={UW} alt="University of Washington" />
    <img className="ucsf" src={UCSF} alt="UCSF - University of California, San Francisco" />
    <img className="bedsider" src={PoweredByBedsider} alt="Powered by Bed sider" />
  </footer>
);
