// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Brackets, IconBar, ResultBar, MobileBracketsAndIconBars } from '../../shared/IconBarParts';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import type { PagePropsWithBcEnglishNames } from '../../../lib/types';
import '../../shared/IconArrayPage.scss';
import './HowOften.scss';

type HowOftenState = {
  selectedIconNum: number | null,
};

type HowOftenProps = PagePropsWithBcEnglishNames & {
  setModalInvisible: () => {},
  setModalVisible: () => {},
};

type CalendarBarProps = {
  isInfinity?: boolean,
  isMonthBar?: boolean,
  +activeMethodClassName: string,
  +freq: number,
  +duration: number,
};

type YearBoxProps = {
  item: number | string,
  children: React.ChildrenArray<*>,
};

type YearBoxPeakProps = YearBoxProps & {
  float: string,
};

const methods = [
  'femaleCondom',
  'maleCondom',
  'diaphragm',
  'pill',
  'patch',
  'ring',
  'shot',
  'implant',
  'iud',
  'sterilization'
];

const InfinityArrow = ({ type }: { type: string}): React.Element<'div'> => (
  <div className={`infinity-arrow-container ${type}`}>
    <div className="infinity-arrow" />
    <div className="infinity-symbol" />
  </div>
);

const YearBoxPeak = ({ item, children, float = '' }: YearBoxPeakProps): React.Element<'div'> => (
  <div className={`year-box peak ${float}`} key={item}>
    <div className="year-text-container">
      <div className="year-text">
        <p>{item}</p>
      </div>
    </div>
    {children}
  </div>
);

const YearBoxRegular = ({ item, children }: YearBoxProps): React.Element<'div'> => (
  <div className="year-box" key={item}>
    <p>{item}</p>
    {children}
  </div>
);

const monthArr = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const CalendarBar = ({ /* isEveryOther, */ activeMethodClassName, freq, duration, isInfinity, isMonthBar }: CalendarBarProps): React.Element<*> => {
  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const years = new Array(duration).fill(0).map((v: number, i: number): number => i + thisYear);
  const extraYears = new Array(3).fill(0).map((v: number, i: number): number => i + thisYear + duration);
  let calendarItems = years;
  if (isMonthBar) calendarItems = monthArr.slice(thisMonth).concat(monthArr.slice(0, thisMonth));
  let counter = 0;

  return (
    <div className={`calendar ${activeMethodClassName}`} aria-hidden>
      <div className="regular">
        <div className="year-box-container">
          {
            (isInfinity ? [...calendarItems, ...extraYears] : calendarItems).map((item: number | string, j: number, arr: Array<*>): React.Element<*> => {
              if (isInfinity && j === arr.length - 1) return <InfinityArrow key={item} type="right" />;
              if (j % freq === 0) {
                return (
                  <YearBoxPeak item={item} key={item}>
                    <div className="down-arrow" />
                    <div className="long-pole pole" />
                  </YearBoxPeak>
                );
              }
              return (
                <YearBoxRegular item={item} key={item}>
                  <div className="long-pole pole" />
                </YearBoxRegular>
              );
            })
          }
        </div>
        <div className="main-bar" />
      </div>
      <div className="mobile">
        <div className="main-bar" />
        { isInfinity ? <InfinityArrow type="down" /> : null }
        <div className="pole-container">
          {
            (activeMethodClassName === 'implant' ? [...calendarItems, ...extraYears] : calendarItems).map(
              (item: number | string): React.Element<'div'> => <div className="pole" key={item} />
            )
          }
        </div>
        <div className="year-box-container">
          {
            (activeMethodClassName === 'implant' ? [...calendarItems, ...extraYears] : calendarItems).map(
              (item: number | string, j: number): React.Node => {
                if (j % freq === 0) {
                  counter += 1;
                  return (
                    <YearBoxPeak key={item} item={item} float={counter % 2 === 0 ? 'right' : 'left'}>
                      <div className="left-right-arrow" />
                    </YearBoxPeak>
                  );
                }
                return null;
              }
            )
          }
        </div>
      </div>
    </div>
  );
};

CalendarBar.defaultProps = {
  isInfinity: false,
  isMonthBar: false,
};

const calendarBarGenerator = (num: number | null): React.Node => {
  if (num === null) return null;

  const activeMethodClassName = methods[num];
  if (num < 6) return <div className={`calendar ${activeMethodClassName}`} />;
  if (num === 6) return <CalendarBar activeMethodClassName={activeMethodClassName} freq={3} duration={12} isMonthBar />;
  if (num === 7) return <CalendarBar activeMethodClassName={activeMethodClassName} freq={3} duration={7} />;
  if (num === 8) {
    return (
      <CalendarBar
        activeMethodClassName={activeMethodClassName}
        freq={12}
        duration={13}
      />
    );
  }
  return (
    <CalendarBar
      activeMethodClassName={activeMethodClassName}
      freq={100}
      duration={10}
      isInfinity
    />
  );
};

const mobileConfig = [3, 4, 3];

class HowOften extends React.Component<HowOftenProps, HowOftenState> {
  _modalParagraph: HTMLParagraphElement;

  constructor(props: HowOftenProps) {
    super(props);
    this.state = { selectedIconNum: null };
    this.modalParagraphRef = (c: HTMLHeadingElement) => { this._modalParagraph = c; };
    this.mobileButtonRefs = [];
    methods.forEach((_: number, i: number) => {
      this.mobileButtonRefs[i] = React.createRef();
    });
  }

  componentDidMount() {
    if (window.innerWidth > 720) setTimeout(() => { this.setState({ selectedIconNum: 0 }); }, 10);
  }

  bcIconClickHandler = (num: number | null, mode: string) => {
    const { setModalInvisible, setModalVisible } = this.props;
    const { selectedIconNum: prevSelectedIconNum } = this.state;
    this.setState({ selectedIconNum: num });

    if (mode === 'mobile') {
      if (num === null) {
        setModalInvisible();
        setTimeout(() => { this.mobileButtonRefs[prevSelectedIconNum].current.focus(); }, 10);
      } else {
        setModalVisible();
        setTimeout(() => { this._modalParagraph.focus(); }, 10);
      }
    }
  }

  render() {
    const { content, isAnyModalVisible, locale } = this.props;
    const { selectedIconNum } = this.state;

    const isModalOn = selectedIconNum !== null;
    const heading = selectedIconNum === null ? '' : `# ${content.methodNames[methods[selectedIconNum]]}`;
    const fullParagraph = selectedIconNum === null ? '' : content.bcResults[selectedIconNum];
    const commonPropsForIconResult = {
      iconNameArr: methods,
      selectedIconNum,
      isAnyModalVisible,
      content,
    };

    return (
      <>
        <div className="bc-plain-texts-container" aria-hidden={isAnyModalVisible}>
          <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
          <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
          { content.hiddenText ? <h2 className="screen-reader-only" aria-hidden={isAnyModalVisible}>{content.hiddenText}</h2> : null }
        </div>
        <div className="bc-bars-and-brackets-container regular">
          <Brackets rangeArr={content.ranges} />
          <IconBar
            clickHandler={this.bcIconClickHandler}
            locale={locale}
            resultArr={content.bcResults}
            {...commonPropsForIconResult}
          />
          <ResultBar
            resultArr={content.bcResults}
            {...commonPropsForIconResult}
          />
          { calendarBarGenerator(selectedIconNum) }
        </div>
        <MobileBracketsAndIconBars
          rangeArr={content.ranges}
          clickHandler={(num: number | null) => { this.bcIconClickHandler(num, 'mobile'); }}
          mobileConfig={mobileConfig}
          mobileButtonRefs={this.mobileButtonRefs}
          resultArr={content.bcResults}
          {...commonPropsForIconResult}
        />
        <div className="mobile mobile-graphics-container">
          <ModalWithOverlay
            isModalOn={isModalOn}
            clickHandler={() => { this.bcIconClickHandler(null, 'mobile'); }}
            closeBox={content.closeBox}
          >
            <Headings externalHeading1Ref={this.modalParagraphRef} str={heading} />
            <MarkdownBlock str={fullParagraph} />
            {calendarBarGenerator(selectedIconNum)}
            <div className="reserved-space" />
          </ModalWithOverlay>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(null, mapDispatchToProps)(HowOften);
