// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SinglePartSurveyQuestion from '../../shared/survey/SinglePartSurveyQuestion';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import NavContainer from '../../shared/nav/NavContainer';
import SurveyActions from '../../../actions/SurveyActions';
import noteActions from '../../../actions/noteActions';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';

export const questionConfig1 = [
  { type: 'single', questionKey: 'timing', choiceKeys: ['never', 'subYear', 'superYear', 'SUPERYEAR'] },
  { type: 'single', questionKey: 'effectiveness', choiceKeys: ['not', 'somewhat', 'very'] },
  { type: 'single', questionKey: 'frequency', choiceKeys: ['negative', 'neutral', 'positive'] },
  {
    type: 'multi',
    questionKey: 'frequencyEnthusiasms',
    subkeys: ['session', 'day', 'week', 'month', 'quarter', 'superyear', 'permanent'],
    choiceKeys: ['positive', 'neutral', 'negative'],
  },
];

class Survey1MBC extends React.Component<SurveyMBCProps> {
  componentDidMount() { this.props.onHideInstructions(); }

  render() {
    const {
      locale, navLinks, surveyStateMBC, answerSurveySingleMBC, answerSurveyMultiMBC, isAnyModalVisible,
      content: { headings, paragraphs, notes, navigation, survey1MbcQuestions, hiddenText }
    } = this.props;

    const isNextButtonHidden = !questionConfig1.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));
    const commonQuestionPropsArr = questionConfig1.map((config: Object, index: number): Object => ({ ...config, ...survey1MbcQuestions[index] }));

    return (
      <>
        <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
        { hiddenText ? <h2 className="screen-reader-only">{hiddenText}</h2> : null }
        <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
        <div className="survey-questions-container">
          <SinglePartSurveyQuestion
            clickHandler={answerSurveySingleMBC}
            currentChoice={surveyStateMBC[questionConfig1[0].questionKey]}
            questionIndex={0}
            ariaHidden={isAnyModalVisible}
            MBC
            {...commonQuestionPropsArr[0]}
          />
          {
            isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig1[0].questionKey]) && (
              <SinglePartSurveyQuestion
                clickHandler={answerSurveySingleMBC}
                currentChoice={surveyStateMBC[questionConfig1[1].questionKey]}
                questionIndex={1}
                ariaHidden={isAnyModalVisible}
                MBC
                {...commonQuestionPropsArr[1]}
              />
            )
          }
          {
            isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig1[1].questionKey]) && (
              <SinglePartSurveyQuestion
                clickHandler={answerSurveySingleMBC}
                currentChoice={surveyStateMBC[questionConfig1[2].questionKey]}
                questionIndex={2}
                ariaHidden={isAnyModalVisible}
                MBC
                {...commonQuestionPropsArr[2]}
              />
            )
          }
          {
            isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig1[2].questionKey]) && (
              <MultiPartSurveyQuestion
                clickHandler={answerSurveyMultiMBC}
                currentChoice={surveyStateMBC[questionConfig1[3].questionKey]}
                questionIndex={3}
                ariaHidden={isAnyModalVisible}
                MBC
                {...commonQuestionPropsArr[3]}
              />
            )
          }
        </div>
        <NavContainer
          locale={locale}
          isHidingNextButton={isNextButtonHidden}
          nextPage={navLinks[1]}
          notesContent={notes}
          navigationContent={navigation}
        />
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMBC }: State): Object => ({ surveyStateMBC });
const mapDispatchToProps = { ...SurveyActions, ...noteActions };

export default connect(mapStateToProps, mapDispatchToProps)(Survey1MBC);
