// @flow

import * as React from 'react';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PageProps } from '../../../lib/types';
import BurstImage from './side-effects-burst.png';
import './SideEffectPreface.scss';

const SideEffectPreface = ({ content, isAnyModalVisible }: PageProps): React.Element<*> => (
  <>
    <img className="burst-image" src={BurstImage} alt="" aria-hidden />
    <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
    <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
  </>
);

export default SideEffectPreface;
